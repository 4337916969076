<template>
  <div class="p-20 bg-white" style="min-height: 810px">
    <a-select v-model="filter" @change="jumpPage(1)">
      <a-select-option :value="0">全部</a-select-option>
      <a-select-option :value="1">待审核</a-select-option>
      <a-select-option :value="2">审核成功</a-select-option>
      <a-select-option :value="3">审核失败</a-select-option>
    </a-select>

    <div v-if="comments.length">
      <div
        class="mt-20 hover:shadow"
        v-for="item in comments"
        :key="item.id"
        @click="open(item)"
      >
        <evaluate-comment-card :dataSource="item"> </evaluate-comment-card>
      </div>
    </div>

    <list-empty v-else></list-empty>

    <div v-if="comments.length" class="text-center py-20">
      <a-pagination
        :pageSize="limit"
        v-model="page"
        :total="total"
        @change="jumpPage"
      ></a-pagination>
    </div>
  </div>
</template>

<script>
import {
  getProComments,
  delComment,
  setCommentVisible,
} from "@/api/comment.js";
export default {
  data() {
    return {
      comments: [],
      total: 0,
      page: 1,
      limit: 5,
      filter: 0,
      visible: false,
      current: {},
    };
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
  },
  created() {
    this.jumpPage(1);
  },
  methods: {
    getProComments(params) {
      getProComments(params).then(({ rows, count }) => {
        this.comments = rows;
        this.total = count;
      });
    },
    jumpPage(e) {
      const params = {
        offset: (e - 1) * this.limit,
      };
      params.status = this.filter?this.filter:undefined;
      params.limit = this.limit;
      this.getProComments(params);
    },
    delComment(id) {
      delComment(id)
        .then(() => {
          this.$message.success("删除提问成功");
          this.visible = false;
          this.jumpPage(this.page);
        })
        .catch(() => {
          this.$message.error("删除提问失败");
        });
    },
    open(data) {
      this.visible = true;
      this.current = data;
    },
    setCommentVisible(id, visible) {
      setCommentVisible(id, { public: visible }).then(() => {
        this.$message.success("设置可见性成功");
      });
    },
  },
};
</script>